import React from "react";
import Container from "../Container";
import { StyledVideo } from "../../styled/aboutus";
import { Colors } from "../../colors";

const Video = ({ url }) => {
  const { paleGrey } = Colors;

  const head = url?.split("=")[1];

  return (
    <Container color={paleGrey}>
      <StyledVideo>
        <iframe
          width="600"
          height="350"
          src={`https://www.youtube.com/embed/${head || "MbOzaxioID4"}`}
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </StyledVideo>
    </Container>
  );
};

export default Video;
