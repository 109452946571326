import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Container from "../components/Container";
import HeroSlider from "../components/homepage/HeroSlider";
import useWindowDimensions from "../hooks/useWindowSize";
import { Hero, SubText, Partners, About } from "../styled/homepage";
import PartnersSlider from "../components/homepage/PartnersSlider";
import AboutUs from "../components/homepage/AboutUs";
import Vision from "../components/homepage/Vision";
import Products from "../components/homepage/Products";
import Testimonials from "../components/homepage/Testimonials";
import Faq from "../components/homepage/Faq";
import Question from "../components/homepage/Question";
import Newsletter from "../components/homepage/Newsletter";
import Footer from "../components/Footer";
import { useQuery } from "react-query";
import { api } from "../api";
import { Element } from "react-scroll";

interface DataType {}

const IndexPage = () => {
  const { width } = useWindowDimensions();

  const [data, setData] = useState([]);

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getPartner";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const { data: gallery } = useQuery(
    ["Gallery"],
    () => {
      return api.getGallery();
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.message }
  );

  // console.log(gallery);

  // console.log(data);

  return (
    <Layout>
      <Container>
        <Hero>
          <h2>Welcome to</h2>
          <h1>
            Joakins <span>Farms</span>
          </h1>
          <h3>Nigeria Limited</h3>
        </Hero>
        <HeroSlider width={width} gallery_data={gallery} />
        <SubText>
          <p>
            Explore our integrate livestock solutions - from egg production to
            fishery, cattle and piggery farming. Discover sustainable practices,
            superior quality and a commitment to nourishing communities.
          </p>
        </SubText>
      </Container>
      <Element name="partners">
      <Partners>
        <h1>Meet our partners</h1>
        <PartnersSlider />
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
            width: "90%",
            margin: "0 auto",
            flexWrap: "wrap",
          }}
        >
          {data?.map((image,index) =><img src={`https://34.228.80.5.nip.io/${image?.url}/${image?.companyLogo}`} alt="webflow" key={index} width={100} height={100} />)}
        </div> */}
      </Partners>
      </Element>
      <Element name="aboutus">
      <About>
        <Container>
          <AboutUs />
        </Container>
      </About>
      </Element>
      <Container>
        <Vision />
        <Element name="ourproducts">
        <Products />
        </Element>
      </Container>
      <Element name="testimonials">
      <Testimonials />
      </Element>
      <Element name="faq">
      <Faq />
      </Element>
      {/* <Question /> */}
      <Newsletter />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
