import React from "react";
import Container from "../Container";
import { StyledStory, StyledStoryImage } from "../../styled/aboutus";
import image from "../../assets/story/image.png";
import { Flex } from "../../styled";
import { Element } from "react-scroll";

interface StoryProps {
  data:string;
}

const Story:React.FC<StoryProps> = ({ data }) => {

  return (
    <Element name="ourstory">
    <Container>
      <StyledStory id='story'>
        <div>
          <span>Our Story</span>
          <h1>Our Journey: Cultivating Excellence since 2005</h1>
        </div>
        <p>
          {data ||
            `At Joakins Farms, our story began in July 2005, a tale of passion, commitment, and the pursuit of excellence in integrated livestock farming. From our modest start with seven dedicated employees and a vision, we've cultivated a legacy spanning over 18 years. Explore the chapters of our journey, marked by growth, sustainability, and a deep-rooted connection to the communities we serve.`}
        </p>
      </StyledStory>
      <Flex>
        <StyledStoryImage src={image} alt="story" />
      </Flex>
    </Container>
    </Element>
  );
};

export default Story;
