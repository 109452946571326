import React from "react";
import ratingLogo from "../assets/icons/star.png";
import ratingGreyLogo from "../assets/icons/star (1).png";

interface RatingsProps {
  rating?: number;
}

const Star = () => {
  return <img src={ratingLogo} alt="Rating" width={15} height={15} />;
};

const GreyStar = () => {
  return <img src={ratingGreyLogo} alt="Rating" width={15} height={15} />;
};

const Ratings: React.FC<RatingsProps> = ({ rating }) => {
  const renderRatingLogos = () => {
    switch (rating) {
      case 1:
        return (
          <>
            <Star />
            <GreyStar />
            <GreyStar />
            <GreyStar />
            <GreyStar />
          </>
        );
      case 2:
        return (
          <>
            <Star />
            <Star />
            <GreyStar />
            <GreyStar />
            <GreyStar />
          </>
        );
      case 3:
        return (
          <>
            <Star />
            <Star />
            <Star />
            <GreyStar />
            <GreyStar />
          </>
        );
      case 4:
        return (
          <>
            <Star />
            <Star />
            <Star />
            <Star />
            <GreyStar />
          </>
        );
      case 5:
        return (
          <>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderRatingLogos()}</div>;
};

export default Ratings;
