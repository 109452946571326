import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../colors";
import { baseUrl } from "../../url";
import useWindowDimensions from "../../hooks/useWindowSize";
import GallerySlider from "./GallerySlider";
import { useQuery } from "react-query";
import { api } from "../../api";

const { primary } = Colors;

const StyledGallery = styled.div`
  h1 {
    text-align: center;
    color: ${primary};
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
  }

  .showcase {
    padding: 20px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
`;

const PhotoGallery = () => {
  const [data, setData] = useState([]);

  const { width } = useWindowDimensions();

  const isDesktop = width > 768;

  const getAboutUs = async () => {
    const url = `${baseUrl}/api/v1/getGallery?sort=-dateCreated`;
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const { data: gallery } = useQuery(
    ["Gallery"],
    () => {
      return api.getGallery();
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.message }
  );

  // console.log(data);

  return (
    <StyledGallery>
      <h1>See our photo Gallery</h1>
      {isDesktop ?
      <div className="showcase">
        
        {data?.map((image) => (
          <img
            key={image?.id}
            src={`${baseUrl}/${image?.url}/${image?.picture}`}
            alt="gallery"
            width={width > 768 ? 400 : 200}
            height={width > 768 ? 300 : 150}
          />
        ))}
      </div> : <GallerySlider width={width} gallery_data={gallery} /> }
    </StyledGallery>
  );
};

export default PhotoGallery;
