import React, { Component, ReactNode } from "react";
import ItemsCarousel from "react-items-carousel";
import styled from "styled-components";
import Container from "../Container";
import { baseUrl } from "../../url";

import Image1 from "../../assets/hero_sliders/image1.png";
import Image2 from "../../assets/hero_sliders/image2.jpeg";
import Image3 from "../../assets/hero_sliders/image3.jpeg";
import Image4 from "../../assets/hero_sliders/image4.jpeg";
import Image5 from "../../assets/hero_sliders/image5.jpeg";

const noOfCards = 1;
const autoPlayDelay = 4000;
const chevronWidth = 10;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ResponsiveImage = styled.img`
  width: 60%;
  height: 400px;
  border-radius: 10px;

  @media (max-width: 1000px) {
    max-width: 80%;
    height: 300px;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    height: 200px;
  }
`;

const Images = [
  { id: 1, image: Image1 },
  { id: 2, image: Image2 },
  { id: 2, image: Image3 },
  { id: 2, image: Image4 },
  { id: 2, image: Image5 },
];

interface AutoPlayCarouselState {
  activeItemIndex: number;
  itemsLength: number;
}

interface AutoPlayCarouselProps {
  width: number;
  gallery_data?: any;
}

class AutoPlayCarousel extends Component<
  AutoPlayCarouselProps,
  AutoPlayCarouselState
> {
  private interval: NodeJS.Timeout | null = null;

  state: AutoPlayCarouselState = {
    activeItemIndex: 0,
    itemsLength: 0,
  };

  componentDidMount() {
    const { gallery_data } = this.props;
    this.interval = setInterval(this.tick, autoPlayDelay);

    this.setState({ itemsLength: gallery_data?.length || 5 }, () => {
      // console.log("Updated itemsLength:", this.state.itemsLength);
    });
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  tick = () =>
    this.setState((prevState) => ({
      activeItemIndex:
        (prevState.activeItemIndex + 1) %
        (prevState.itemsLength - noOfCards + 1),
    }));

  onChange = (value: number) => {
    const { gallery_data } = this.props;

    this.setState({
      activeItemIndex: value,
      itemsLength: gallery_data?.length,
    });
  };

  render() {
    const { gallery_data } = this.props;

    const Items: ReactNode[] = gallery_data?.map((item) => (
      <Flex>
        <ResponsiveImage
          key={item.id}
          src={`${baseUrl}/${item?.url}/${item?.picture}`}
          alt="Logo"
        />
      </Flex>
    ));

    const SubItems: ReactNode[] = Images?.map((item) => (
      <Flex key={item.id}>
        <ResponsiveImage  src={item.image} alt="Logo" />
      </Flex>
    ));
    
    return (
      <Container>
        <ItemsCarousel
          gutter={12}
          numberOfCards={1}
          activeItemIndex={this.state.activeItemIndex}
          requestToChangeActive={this.onChange}
          //   rightChevron=">"
          //   leftChevron="<"
          chevronWidth={chevronWidth}
          outsideChevron
          activePosition={"center"}
          children={Items ? Items : SubItems}
          infiniteLoop
        />
      </Container>
    );
  }
}

export default AutoPlayCarousel;
