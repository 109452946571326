import React from "react";
import Container from "./Container";
import styled from "styled-components";
import { Colors } from "../colors";
import footerImg from "../assets/footer.png";
import Facebook from "../assets/icons/Facebook.png";
import Instagram from "../assets/icons/Instagram.png";
import Twitter from "../assets/icons/Twitter.png";
import LinkedIn from "../assets/icons/LinkedIn.png";
import { useQuery } from "react-query";
import { api } from "../api";

const { primary } = Colors;

const StyledFooter = styled.div`
  background-color: ${primary};
  padding: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  hr {
    width: 100%;
    margin: 20px 0;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 13px;
  font-weight: 300;

  p {
    color: #fff;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    li {
      text-decoration: underline;
      list-style-type: none;
    }
  }
`;

const Footer = () => {
  const { data: contactUs } = useQuery(
    ["Contact Us"],
    () => {
      return api.getContactUs();
    },
    { cacheTime: 30000, staleTime: 30000, select: (data) => data?.message }
  );

  // console.log(contactUs);

  return (
    <StyledFooter>
      <Container>
        <InnerContainer>
          <img src={footerImg} alt="logo" width={140} height={50} />
          <Links>
            <a href={contactUs?.faceBook} target="_blank" rel="noreferrer">
              <img src={Facebook} alt="facebook" />
            </a>
            <a href={contactUs?.instagram} target="_blank" rel="noreferrer">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a href={contactUs?.twitter} target="_blank" rel="noreferrer">
              <img src={Twitter} alt="Twitter" />
            </a>
            <a href={contactUs?.linkedin} target="_blank" rel="noreferrer">
              <img src={LinkedIn} alt="Linked In" />
            </a>
          </Links>
          <hr />
          <Bottom>
            <p> &copy; 2023 joakinsfarms. All rights reserved.</p>
            <ul>
              <li>Privacy Policy</li>
              <li>Terms of Service</li>
              <li>Cookies Settings</li>
            </ul>
          </Bottom>
        </InnerContainer>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
