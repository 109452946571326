import React from "react";
import Container from "../Container";
import { Colors } from "../../colors";
import {
  StyledWhoWeAre,
  StyledWhoWeAreImage,
  ImageContainer,
} from "../../styled/aboutus";
import image from "../../assets/whoweare/image.png";
import { Element } from "react-scroll";

const { paleGreen } = Colors;

const WhoWeAre = ({ data }) => {
  // console.log(data);

  return (
    <Element name="whoweare">
      <div id="whoweare" style={{ backgroundColor: paleGreen }}>
        <Container color={paleGreen}>
          <StyledWhoWeAre>
            <ImageContainer>
              <StyledWhoWeAreImage src={image} alt="who we are" />
            </ImageContainer>
            <div>
              <span>Who we are</span>
              <h1>Joakins Farms Nigeria Limited: A Legacy of Excellence</h1>
              <p>
                {data ||
                  `As a homegrown pioneer, Joakins Farms has evolved into a dynamic force in integrated livestock farming. Nestled in the heart of Ilodo, Isonyin, Ogun State, our commitment to sustainable practices, innovation, and community nourishment is at the core of who we are. Meet the faces behind the farm and explore the values that drive us forward.`}
              </p>
            </div>
          </StyledWhoWeAre>
        </Container>
      </div>
    </Element>
  );
};

export default WhoWeAre;
