import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import ContactUs from "../components/contact/ContactUs";
import Map from "../components/contact/Map";
import Footer from "../components/Footer";
import ContactDetails from "../components/contact/ContactDetails";

interface DataProps {
    officeLocation?:any;
}

const Contact = () => {
  const [data, setData] = useState<DataProps>({});

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getContactUs";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

//   console.log(data);

  return (
    <Layout>
      <ContactUs />
      <Map coordinates={data?.officeLocation} />
      <ContactDetails data={data} />
      <Footer />
    </Layout>
  );
};

export default Contact;
