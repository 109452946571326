import React from "react";
import hamburger from "../assets/icons/hamburger.png";
import styled from "styled-components";

const StyledBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

interface BtnProps {
  clickHandler: Function;
}
const HamburgerBtn: React.FC<BtnProps> = ({ clickHandler }) => {

  return (
    <StyledBtn onClick={() => clickHandler()}>
      <img src={hamburger} alt="button" width={30} height={30} />
    </StyledBtn>
  );
};

export default HamburgerBtn;
