import styled from "styled-components";
import { ContainerProps } from "../interfaces";

const Container = styled.div<ContainerProps>`
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px;
  background-color:${({ color }) => color || "transparent"};
  padding:${({padding}) => padding || '10px'};
`;

export default Container;
