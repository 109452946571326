import styled from "styled-components";
import { Colors } from "../colors";
import { ListProps, FlexProps } from "../interfaces";
import { Link } from "react-router-dom";

const { primary, gray } = Colors;

export const StyledNavLink = styled(Link)`
  text-decoration: none !important;
  position: relative;
  color: ${gray} !important;
  text-decoration-line: none !important;

  &:hover {
    color: ${primary};
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    margin: 10px 0;
    background-color: ${primary};
    transition-duration: 0.3s;
    transform: scale(0);
  }

  &.active:after {
    transform: scale(1);
  }
`;

export const StyledSideBar = styled.div`
  position: fixed;
  background-color: white;
  height: 99.8vh;
  width: 60%;
  border: 1px solid white;
  box-shadow: 0 0 2px 2px #eaeaea;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 10000;
  flex-direction: column;
  gap: 20px;
`;

export const StyledList = styled.div<ListProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  font-size: 14px;
  flex-direction: ${({ direction }) => direction || "row"};
  position:relative;


`;

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ align }) => align || "flex-start"};
  justify-content: ${({ justify }) => justify || "flex-start"};
  flex-direction: ${({ direction }) => direction || "row"};
  gap: ${({ gap }) => gap || "0px"};
`;
