import * as React from "react";
import Container from "./Container";
import StyledButton from "./Button";
import NavList from "./NavList";
import Logo from "../assets/logo.png";
import useWindowDimensions from "../hooks/useWindowSize";
import HamburgerBtn from "./HamburgerBtn";
import Sidebar from "./Sidebar";
import { baseUrl } from "../url";
import { useNavigate } from "react-router-dom";
import { Colors } from "../colors";

interface DataProps {
  shop?: string;
}

const { secondary } = Colors;

const anchorStyle = {
  backgroundColor: secondary,
  outline: `none`,
  padding: `15px 20px`,
  fontSize: "14px",
  borderRadius: "5px",
  color: "white",
  border: "none",
  fontFamily: `"Lato", sans-serif`,
  cursor: "pointer",
  transitionDuration: "0.25s",
};

const Header = () => {
  const { width } = useWindowDimensions();

  const isDesktop = width > 768;

  const [sideNav, showSideNav] = React.useState(false);

  const handleSideNav = () => {
    showSideNav(!sideNav);
  };

  const [data, setData] = React.useState<DataProps>({});

  const navigate = useNavigate();

  const getAboutUs = async () => {
    const url = `${baseUrl}/api/v1/getContactUs`;
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  React.useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // console.log(data?.shop);

  const handleNavigate = () => {
    // Use navigate to go to the specified URL
    // navigate(data?.shop);
    window.open(`${data?.shop}`, "_blank");
  };

  React.useEffect(() => {
    if (isDesktop) {
      showSideNav(false);
    }
  }, [width]);

  const renderItems = React.useMemo(() => {
    if (!isDesktop) return <HamburgerBtn clickHandler={handleSideNav} />;

    return (
      <>
        <NavList />
        <StyledButton
          target="_blank"
          rel="noreferrer"
          href={data?.shop || "https://www.joakinsfarms.ng"}
        >
          Visit Store
        </StyledButton>
        {/* <a
          href={data?.shop ? data?.shop : "www.joakinsfarms.ng"}
          target="_blank"
          rel="noreferrer"
        >
          Visit Store
        </a> */}
      </>
    );
  }, [isDesktop]);

  return (
    <Container>
      <div className="header">
        <img src={Logo} alt="Logo" width={90} height={35} />
        {renderItems}
      </div>
      {sideNav && <Sidebar clickHandler={handleSideNav} />}
    </Container>
  );
};

export default Header;
