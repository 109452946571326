import React from "react";
import { StyledSideBar } from "../styled";
import XBtn from "./XBtn";
import NavList from "./NavList";
import StyledButton from "./Button";

interface SideProps {
  clickHandler: Function;
}

const btn_style = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "5px",
};

const Sidebar: React.FC<SideProps> = ({ clickHandler }) => {
  return (
    <StyledSideBar>
      <div style={btn_style}>
        <XBtn clickHandler={() => clickHandler()} />
      </div>
      <NavList direction="column" />
      <StyledButton>Join our newsletter</StyledButton>
    </StyledSideBar>
  );
};

export default Sidebar;
