import React from 'react';
import { StyledNavLink } from "../styled";
import {useLocation} from 'react-router-dom';

interface ActiveLinkProps {
  key?:any;
  href?: string;
  children?:any;
}

const ActiveLink: React.FC<ActiveLinkProps> = ({ href, children }) => {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <StyledNavLink to={href} className={isActive ? "active" : ""}>
      {children}
    </StyledNavLink>
  );
};

export default ActiveLink;
