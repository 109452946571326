import axios from "axios";
import { baseUrl } from "../url";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
  },
});

client.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.config.url !== "/login") {
      sessionStorage.setItem("isLoggedIn", JSON.stringify(false));
    }
    return Promise.reject(error);
  }
);

export const api = {
  getGallery: () =>
    client.get("/api/v1/getGallery?sort=-dateCreated").then(({ data }) => data),

  getAboutUs: () =>
    client.get("/api/v1/getAboutUs?sort=-dateCreated").then(({ data }) => data),

    getContactUs: () =>
    client.get("/api/v1/getContactUs").then(({ data }) => data),
};
