import React from "react";
import styled from "styled-components";
import Container from "../Container";
import email from "../../assets/icons/email.png";
import chat from "../../assets/icons/chat.png";
import phone from "../../assets/icons/phone.png";
import location from "../../assets/icons/location.png";
import store from "../../assets/icons/store.png";
import { Colors } from "../../colors";

const { primary } = Colors;

const StyledDetails = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;

  div {
    max-width: 250px;
    width: 100%;
    margin-bottom: 10px;
    min-height: 120px;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      a {
        text-align:center;
      }
    }

    h1 {
      font-size: 20px;
      margin: 0;
      color: ${primary};
    }

    a {
      font-size: 14px;
      margin: 0;
      color: ${primary};
    }
  }
`;

interface DetailsProps {
  data?: any;
}

const ContactDetails: React.FC<DetailsProps> = ({ data }) => {
  // console.log(data);

  return (
    <Container>
      <StyledDetails>
        <div>
          <img src={email} alt="email" width={30} height={30} />
          <h1>Email</h1>
          <a href={`mailto:${data?.email}`}>{data?.email}</a>
        </div>
        <div>
          <img src={chat} alt="chat" width={30} height={30} />
          <h1>WhatsApp</h1>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${data?.whatsAppLink
            }`}
          >
            {data?.whatsApp}
          </a>
        </div>
        <div>
          <img src={phone} alt="phone" width={30} height={30} />
          <h1>Phone</h1>
          <a target="_blank" rel="noreferrer" href={`tel:${data?.phoneNumber}`}>
            {data?.phoneNumber}
          </a>
        </div>
        <div>
          <img src={location} alt="location" width={30} height={30} />
          <h1>Office</h1>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/place/${encodeURIComponent(
              data?.officeLocation?.address
            )}`}
          >
            {data?.officeLocation?.address}
          </a>
        </div>
      </StyledDetails>
    </Container>
  );
};

export default ContactDetails;
