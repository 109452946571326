import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import Hero from "../components/aboutus/Hero";
import Story from "../components/aboutus/Story";
import WhoWeAre from "../components/aboutus/WhoWeAre";
import Video from "../components/aboutus/Video";
import Vision from "../components/aboutus/Vision";
import Footer from "../components/Footer";
import Team from "../components/aboutus/Team";
import Values from "../components/aboutus/Values";
interface DataType {
  journey?: string;
  whoWeAre?: string;
  mission?: string;
  vision?: string;
  youtubeUrl?: string;
  coreValues?: string;
}

const AboutPage = () => {
  const [data, setData] = useState<DataType>({});

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getAboutUs";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // console.log(data);

  return (
    <Layout>
      <Hero />
      <hr />
      <Story data={data?.journey} />
      <WhoWeAre data={data?.whoWeAre} />
      <Video url={data?.youtubeUrl} />
      <Vision />
      <Team />
      <Values data={data?.coreValues} />
      <Footer />
    </Layout>
  );
};

export default AboutPage;
