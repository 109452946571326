import React from 'react';
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/index";
import AboutPage from "./pages/about";
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

function App() {
  return (
    <React.Fragment>
      <ToastContainer bodyStyle={{fontSize:'12px',fontFamily:"'Lato',sans-serif"}} />
      <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/about"} element={<AboutPage />} />
        <Route path={"/gallery"} element={<Gallery />} />
        <Route path={"/contact"} element={<Contact />} />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
