import React, { useState, useEffect } from "react";
import Container from "../Container";
import { StyledTeam } from "../../styled/aboutus";
import { Colors } from "../../colors";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowSize";
import StaffDetails from "./StaffDetails";
import { Element } from "react-scroll";

import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/default";

// or only core styles
import "@splidejs/react-splide/css/core";

const { paleGrey, primary, gray, secondary } = Colors;

const StyledItem = styled.div`
  padding:5px;
  display:flex;
  align-items:center;
  justify:center;
  flex-direction:column;
  gap:5px;

  @media (max-width: 768px) {
    width: 70%;
    margin: 0 auto;
  }

  h2 {
    color: ${primary};
    font-weight: 700;
    font-size: 20px;
    margin:0;

    @media (max-width: 768px) {
        width: 70%;
        font-size: 15px;
      }
  }
}

button {
  background-color:transparent;
  cursor:pointer;
  outline:none;
  border:none;
  color:${secondary};
  font-family:'Lato',sans-serif;

  &:hover {
    text-decoration:underline;
  }
}

h4 {
  color: ${gray};
  font-size: 14px;
  margin:0;
}
`;

interface SliderProps {
  data: any;
  handleViewStaff: Function;
}

const Slider: React.FC<SliderProps> = ({ data, handleViewStaff }) => {
  const { width } = useWindowDimensions();

  return (
    <Splide
      aria-label="Team"
      options={{
        perPage: width > 768 ? 3 : 1,
        arrows: true,
        direction: "ltr",
        gap: "5px",
      }}
    >
      {data.map((item, index) => (
        <SplideSlide key={index}>
          <StyledItem>
            <img
              src={`https://34.228.80.5.nip.io/${item?.url}/${item?.picture}`}
              alt="team"
              width={200}
              height={200}
            />
            <h2>
              {item?.fName} {item?.lName}
            </h2>
            <h4>{item?.role}</h4>
            <button onClick={() => handleViewStaff(item)}>
              Click to view more details...
            </button>
          </StyledItem>
        </SplideSlide>
      ))}
    </Splide>
  );
};

const Team = () => {
  const [data, setData] = useState([]);

  const getAboutUs = async () => {
    const url =
      "https://34.228.80.5.nip.io/api/v1/getTeamMember?sort=websitePosition";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //   console.log(data);
  const [currentStaff, setCurrentStaff] = useState({});
  const [modal, showModal] = useState(false);

  const handleModal = () => {
    showModal(!modal);
  };

  const handleViewStaff = (item) => {
    setCurrentStaff(item);
    handleModal();
    // console.log(item);
  };

  return (
    <Element name="ourteam">
      <Container color={paleGrey}>
        <StyledTeam>
          <span>Our Team</span>
          <h1>Meet Our Dedicated Team</h1>
          <p>
            Our success is driven by the dedication and expertise of our diverse
            team.
          </p>
          <div>
            <Slider data={data} handleViewStaff={handleViewStaff} />
          </div>
        </StyledTeam>
        {modal && (
          <StaffDetails currentStaff={currentStaff} handleModal={handleModal} />
        )}
      </Container>
    </Element>
  );
};

export default Team;
