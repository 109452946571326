import React from "react";
import styled from "styled-components";
import Container from "../Container";
import { Colors } from "../../colors";

const { primary } = Colors;

const StyledContactUs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    color: ${primary};
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
  }
`;

const ContactUs = () => {
  return (
    <Container>
      <StyledContactUs>
        <h1>Get in touch with us</h1>
      </StyledContactUs>
    </Container>
  );
};

export default ContactUs;
