import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../colors";
import FAQ from "react-faq-component";

const { primary, gray } = Colors;

const StyledFaq = styled.div`
  background-color: #fafff3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;

  h1 {
    color: ${primary};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    font-size:25px;

    @media (max-width: 768px) {
      text-align:center;
    }
  }

  p {
    color: ${gray};
    margin-top: 10px;
  }
`;

const InnerFaq = styled.div`
  width: 70%;
  margin: 0 auto;
  overflow: hidden;
  padding: 5px 0;

  .faq-row-wrapper {
    background-color: transparent;
    .faq-title {
    }

    .faq-body {
      .faq-row {
        .row-title {
          padding: 15px 20px;
          font-size: 15px;
          font-family: "Nunito Sans", sans-serif;
        }

        .row-content {
          .row-content-text {
            padding: 15px 20px;
            font-size: 15px;
            font-family: "Nunito Sans", sans-serif;
            color: ${primary};
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const config = {
  animate: true,
  tabFocus: true,
};

const Faq = () => {
  const [data, setData] = useState({
    title: null,
    rows: [],
  });

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getFaq";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData((prev) => {
          return {
            ...prev,
            rows: data?.message?.map((message) => {
              return { title: message?.question, content: message?.answer };
            }),
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <StyledFaq>
      <h1>Frequently Asked Questions</h1>
      <p>Our recently asked questions.</p>
      <InnerFaq>
        <FAQ data={data} config={config} />
      </InnerFaq>
    </StyledFaq>
  );
};

export default Faq;
