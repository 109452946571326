import React, { useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../colors";

interface DetailsProps {
  handleModal?: Function;
  currentStaff?: any;
}

const { primary, gray, sub_secondary, secondary } = Colors;

const Modal = styled.div`
  position: fixed;
  inset: 0;
  z-index: 50;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const StyledDetails = styled.div`
  border: 1px solid #ccc;
  border-radius: 7px;
  background-color: #fff;
  max-width: 700px;
  max-height:400px;
  overflow-y:scroll;
  width: 100%;
  padding: 20px 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  font-family: "Nunito Sans", sans-serif;
  positive:relative;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 20px;
  }

  img {
    border-radius:7px;
  }

  div {
    padding: 10px;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    span {
      background-color: ${sub_secondary};
      color: ${primary};
      padding: 3px 10px;
      border-radius: 8px;
      font-size: 14px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    h1 {
      color: ${primary};
      font-weight: 700;
      font-size: 20px;
      margin:0;

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  h2 {
    color: ${primary};
    font-weight: 700;
    font-size: 16px;
    margin:0;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}


  p {
    color: ${gray};
    font-size: 14px;
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${secondary};
  padding: 10px 20px;
  border-radius: 7px;
`;

const StaffDetails: React.FC<DetailsProps> = ({
  handleModal,
  currentStaff,
}) => {
  const backdropRef = useRef(null);

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
      handleModal();
    }
  };

  //   console.log(currentStaff);

  return (
    <Modal ref={backdropRef} onClick={handleBackdropClick}>
      <StyledDetails>
        <img
          src={`https://34.228.80.5.nip.io/${currentStaff?.url}/${currentStaff?.picture}`}
          alt={`${currentStaff?.fName} ${currentStaff?.lName}`}
          width={300}
          height={300}
        />
        <div>
          <h1>
            {currentStaff?.fName} {currentStaff?.lName}
          </h1>
          <h2>{currentStaff?.role}</h2>
          <p>{currentStaff?.about}</p>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "0",
            }}
          >
            <StyledButton onClick={() => handleModal()}>Close </StyledButton>
          </div>
        </div>
      </StyledDetails>
    </Modal>
  );
};

export default StaffDetails;
