import React, { useState, useEffect } from "react";
import styled from "styled-components";
import box from "../../assets/vision/box.png";
import image from "../../assets/vision/image.png";
import useWindowDimensions from "../../hooks/useWindowSize";
import Container from "../Container";
import { Colors } from "../../colors";
import { Element } from "react-scroll";

const { paleGreen, primary } = Colors;

const StyledVision = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 50px 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
`;

const Section = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const InnerSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;
  font-family: "Nunito Sans", sans-serif;

  h1 {
    margin: 0;
    color: ${primary};
  }
  p {
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
`;

interface DataType {
  mission?: string;
  vision?: string;
  // Add other properties if needed
}

const Vision = () => {
  const { width } = useWindowDimensions();

  const [data, setData] = useState<DataType>({});

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getAboutUs";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Element name="mission">
      <div style={{ backgroundColor: paleGreen }}>
        <Container color={paleGreen}>
          <StyledVision>
            <Section>
              <InnerSection>
                <img
                  src={box}
                  alt="box"
                  width={22}
                  height={22}
                  style={{ marginTop: "9px" }}
                />
                <div>
                  <h1>Vision</h1>
                  <p>
                    {data?.vision ||
                      `Highlight Unique Selling Propositions with a short summary of the
              key feature and how it benefits customers.`}
                  </p>
                </div>
              </InnerSection>
              <InnerSection>
                <img
                  src={box}
                  alt="box"
                  width={22}
                  height={22}
                  style={{ marginTop: "9px" }}
                />
                <div>
                  <h1>Mission</h1>
                  <p>
                    {data?.mission ||
                      `Highlight Unique Selling Propositions with a short summary of the
              key feature and how it benefits customers.`}
                  </p>
                </div>
              </InnerSection>
            </Section>
            <Section>
              <img
                src={image}
                alt="About Us"
                width={width > 768 ? 410 : 250}
                height={width > 768 ? 350 : 250}
              />
            </Section>
          </StyledVision>
        </Container>
      </div>
    </Element>
  );
};

export default Vision;
