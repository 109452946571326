import React, { useState } from "react";
import ActiveLink from "./ActiveLink";
import { ListProps } from "../interfaces";
import { StyledList } from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

const views = [
  { id: 1, title: "Home", route: "/" },
  { id: 2, title: "About Us", route: "/about" },
  { id: 3, title: "Gallery", route: "/gallery" },
  { id: 4, title: "Contact", route: "/contact" },
];

const subLinks = [
  { id: 1, link: "ourstory", title: "Our Story" },
  { id: 2, link: "whoweare", title: "Who we are" },
  { id: 3, link: "mission", title: "Mission / Vision" },
  { id: 4, link: "ourteam", title: "Our Team" },
  { id: 5, link: "ourvalues", title: "Our Values" },
];

const subHomeLinks = [
  { id: 1, link: "partners", title: "Our Partners" },
  { id: 2, link: "aboutus", title: "Who we are" },
  { id: 3, link: "ourproducts", title: "Our Products" },
  { id: 4, link: "testimonials", title: "Testimonials" },
  { id: 5, link: "faq", title: "F.A.Q" },
];

const NavList: React.FC<ListProps> = ({ direction }) => {
  const [showSub, setShowSub] = useState(false);
  const [showHomeSub, setShowHomeSub] = useState(false);

  const handleShowSub = () => {
    setShowSub(!showSub);
  };

  const handleShowHomeSub = () => {
    setShowHomeSub(!showHomeSub);
  };

  return (
    <StyledList direction={direction}>
      {views.map((view) => (
        <ActiveLink key={view.id} href={view.route}>
          {view.title}
          {"  "}
          {view.id === 1 && (
            <FontAwesomeIcon
              onClick={handleShowHomeSub}
              icon={showHomeSub ? faCaretDown : faCaretUp}
            />
          )}
          {view.id === 2 && (
            <FontAwesomeIcon
              onClick={handleShowSub}
              icon={showSub ? faCaretDown : faCaretUp}
            />
          )}
          {view.id === 2 && showSub && (
            <div
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                width: "100px",
                padding: "5px",
                borderRadius: "7px",
                backgroundColor: "white",
                zIndex: "30",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                top: "25px",
                right:'0px'
              }}
            >
              {subLinks.map((link) => (
                // <a key={link.id} href={link.link}>
                //   {link.title}
                // </a>
                <Link
                  key={link.id}
                  spy={true}
                  smooth={true}
                  duration={500}
                  activeClass="active"
                  to={link.link}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          )}
          {view.id === 1 && showHomeSub && (
            <div
              style={{
                position: "absolute",
                border: "1px solid #ccc",
                width: "100px",
                padding: "5px",
                borderRadius: "7px",
                backgroundColor: "white",
                zIndex: "30",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                top: "25px",
                right:'0px'
              }}
            >
              {subHomeLinks.map((link) => (
                // <a key={link.id} href={link.link}>
                //   {link.title}
                // </a>
                <Link
                  key={link.id}
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  activeClass="active"
                  to={link.link}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          )}
        </ActiveLink>
      ))}
    </StyledList>
  );
};

export default NavList;
