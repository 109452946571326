import React from 'react'
import Layout from '../components/Layout'
import PhotoGallery from '../components/gallery/PhotoGallery'
import Newsletter from '../components/homepage/Newsletter'
import Footer from '../components/Footer'

const gallery = () => {
  return (
    <Layout>
        <PhotoGallery />
        <Newsletter />
        <Footer />
    </Layout>
  )
}

export default gallery