import React from "react";
import styled from "styled-components";
import Container from "../Container";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import useWindowDimensions from "../../hooks/useWindowSize";

import image1 from "../../assets/products/image1.png";
import image2 from "../../assets/products/image2.png";
import image3 from "../../assets/products/image3.png";
import image4 from "../../assets/products/image4.png";
import image5 from "../../assets/products/image5.png";

import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/default";

// or only core styles
import "@splidejs/react-splide/css/core";

const Images = [
  { id: 1, image: image1 },
  { id: 2, image: image2 },
  { id: 2, image: image3 },
  { id: 2, image: image4 },
  { id: 2, image: image5 },
];

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;

// const StyledImage = styled.img`
//   width: 320px;
//   height: 350px;

//   @media (max-width: 768px) {
//     width: 200px;
//     height: 230px;
//   }
// `;

const PartnersSlider = () => {
  const { width } = useWindowDimensions();

  const [data, setData] = React.useState([]);

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getPartner";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  React.useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Splide
      aria-label="Testimonials"
      options={{
        perPage: width > 768 ? 4 : 1,
        arrows: false,
        direction: "ltr",
        autoplay:true
      }}
    >
      {data?.map((image, index) => (
        <SplideSlide key={index}>
          <ImageContainer>
          <img src={`https://34.228.80.5.nip.io/${image?.url}/${image?.companyLogo}`} alt="webflow" key={index} width={100} height={100} />
          </ImageContainer>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default PartnersSlider;
