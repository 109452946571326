import React from "react";
import ProductSlider from "./ProductSlider";
import { StyledProducts, JoakinProducts } from "../../styled/homepage";

const Products = () => {
  return (
    <StyledProducts>
      <span>Our Products</span>
      <p>Discover Our Premium Agricultural Offerings</p>
      <JoakinProducts>
        <ProductSlider />
        {/* <Image src={image1} alt="Image 1" width={300} height={330} />
        <Image src={image2} alt="Image 2" width={300} height={330} />
        <Image src={image3} alt="Image 3" width={300} height={330} />
        <Image src={image4} alt="Image 4" width={300} height={330} />
        <Image src={image5} alt="Image 5" width={300} height={330} /> */}
      </JoakinProducts>
    </StyledProducts>
  );
};

export default Products;
