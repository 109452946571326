import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../colors";
import about from "../../assets/whoweare/img.jpeg";
import useWindowDimensions from "../../hooks/useWindowSize";

const { sub_secondary, primary } = Colors;

const Section = styled.div`
  padding: 10px;
  display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const ImgSection = styled.div`
  padding: 10px;
  display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    img {
      border-radius:10px;
    }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const StyledAbout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  span {
    background-color: ${sub_secondary};
    color: ${primary};
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 14px;
  }

  h1 {
    color: white;
    font-weight: 800;
    font-family: "Nunito Sans", sans-serif;

    @media (max-width: 768px) {
      width: 90%;
      text-align: center;
    }
  }

  h3 {
    color: white;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 14px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  button {
    background: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    color: ${primary};
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }
`;

interface DataType {
  about?: string;
  // Add other properties if needed
}

const AboutUs = () => {
  const { width } = useWindowDimensions();

  const [data, setData] = useState<DataType>({});

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getAboutUs";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <StyledAbout>
      <Section>
        <span>About us</span>
        <h1>About Joakins Farm Nigeria Limited</h1>
        <h3>
          {/* Established in 2005, Joakins Farms Nigeria Limited is a homegrown
          pioneer in integrated livestock farming. Nestled in the heart of
          Ilodo, Isonyin, Ogun state, we have grown from a humble beginning to
          over 20 acres of prime agricultural land. */}
          {data?.about}
        </h3>
        <button>Explore our story</button>
      </Section>
      <ImgSection>
        <img
          src={about}
          alt="About Us"
          width={width > 768 ? 500 : 250}
          height={width > 768 ?300 : 200}
        />
      </ImgSection>
    </StyledAbout>
  );
};

export default AboutUs;
