import React, { ReactNode } from "react";
import Header from "./Header";
import logo from "./logo.png";

type Props = {
  children?: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => (
  <React.Fragment>
    <Header />
    {children}
  </React.Fragment>
);

export default Layout;
