import React from "react";
import xmark from "../assets/icons/x.png";
import styled from "styled-components";

const StyledBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

interface BtnProps {
  clickHandler: Function;
}
const XBtn: React.FC<BtnProps> = ({ clickHandler }) => {
  return (
    <StyledBtn onClick={() => clickHandler()}>
      <img src={xmark} alt="button" width={14} height={14} />
    </StyledBtn>
  );
};

export default XBtn;
