import styled from "styled-components";
import { Colors } from "../colors";

const { secondary } = Colors;

const StyledButton = styled.a`
  background-color: ${secondary};
  outline: none;
  padding: 15px 20px;
  font-size:14px;
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "Lato", sans-serif;
  cursor: pointer;
  transition-duration: 0.25s;
  border: 1px solid ${secondary};

  &:hover {
    background-color: transparent;
    color: ${secondary};
  }
`;

export default StyledButton;
