import styled from "styled-components";
import { Colors } from "../../colors";

const { primary, gray, sub_secondary, secondary } = Colors;

export const StyledHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;

  h1 {
    width: 35%;
    text-align: center;
    color: ${primary};
    font-weight: 800;
    font-size: 35px;

    @media (max-width: 1200px) {
      width: 50%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    width: 60%;
    text-align: center;
    color: ${gray};
    font-size: 14px;
    margin: 0;

    @media (max-width: 1200px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const StyledStory = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  font-family: "Nunito Sans", sans-serif;
  padding: 20px;
  width: 972px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 772px;
  }

  @media (max-width: 768px) {
    width:90%;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }

  div {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    span {
      background-color: ${sub_secondary};
      color: ${primary};
      padding: 3px 10px;
      border-radius: 8px;
      font-size: 14px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    h1 {
      color: ${primary};
      font-weight: 700;
      font-size: 25px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  p {
    color: ${gray};
    font-size: 14px;
  }
`;

export const StyledStoryImage = styled.img`
  width: 972px;
  height: 333px;
  margin: 0 auto 50px auto;

  @media (max-width: 1200px) {
    width: 772px;
    height: 243px;
  }

  @media (max-width: 768px) {
    width: 472px;
    height: 150px;
  }

  @media (max-width: 550px) {
    width: 202px;
    height: 90px;
  }
`;

export const StyledWhoWeAre = styled.div`
  margin: 50px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  font-family: "Nunito Sans", sans-serif;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }

  div {
    padding: 20px;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    span {
      background-color: ${sub_secondary};
      color: ${primary};
      padding: 3px 10px;
      border-radius: 8px;
      font-size: 14px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    h1 {
      color: ${primary};
      font-weight: 700;
      font-size: 25px;

      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }

  p {
    color: ${gray};
    font-size: 14px;
  }
`;

export const StyledWhoWeAreImage = styled.img`
  width: 486px;
  height: 351px;

  @media (max-width: 768px) {
    width: 296px;
    height: 181px;
  }
`;

export const ImageContainer = styled.span`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 10px;
`;

export const StyledTeam = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 10px;
  flex-direction: column;

  .splide {
    width:90%;
    margin:0 auto;

    li {
        .is-active {
            background-color:${secondary};
        }
    }

    @media (max-width: 768px) {
        width:300px;
    margin:0 auto;
      }

      @media (max-width: 400px) {
        width:300px;
    margin:0 auto;
      }
  }
  

  span {
    background-color: ${sub_secondary};
    color: ${primary};
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 14px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  h1 {
    color: ${primary};
    font-weight: 700;
    font-size: 25px;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 20px;
    }
  }
}

p {
  color: ${gray};
  font-size: 14px;
}
`;

export const ValueContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 10px;
  flex-direction: column;

  span {
    background-color: ${sub_secondary};
    color: ${primary};
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 14px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .header {
    color: ${primary};
    font-weight: 700;
    text-align: center;
    width:30%;
    font-size: 25px;

    @media (max-width: 1200px) {
        width:50%;
      }

    @media (max-width: 768px) {
      width:90%;
    }
  }
}

.sub-texts {
  color: ${gray};
  font-size: 14px;
  text-align: center;
    width:50%;

    @media (max-width: 768px) {
        width:90%;
      }
}
`;

export const Grid = styled.div`
  margin-top: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  font-family: "Nunito Sans", sans-serif;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
`;

export const StyledValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;

  h1 {
    margin: 0;
    color: ${primary};
  }

  p {
    margin: 0;
    align-text: center;
  }
`;
