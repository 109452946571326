import React from "react";
import styled from "styled-components";
import Container from "../Container";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customMarkerImage from "../../assets/icons/marker-icon.png";
import Form from "./Form";
import { Colors } from "../../colors";

// const customIcon = new L.Icon({
//     iconUrl:"../../assets/icons/marker-icon.png",
//     iconSize:[50,50],
//     iconAnchor:[50,50],
//     popupAnchor:[50,50]
// })

const {secondary} = Colors;

const StyledMap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .map {
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid ${secondary};
    height: 400px;

    .leaflet-container {
      background-color: transparent;
      height: 400px;
      width: 100%;
    }

    .leaflet-div-icon {
      background-color: transparent;
      border: none;
    }
  }
`;


interface DetailsProps {
  coordinates?:any;
}

const Map:React.FC<DetailsProps> = ({coordinates}) => {

  // console.log(coordinates?.coordinate);

  return (
    <Container color="#FFFAF0" padding="20px">
      <StyledMap>
        <div className="map">
          <MapContainer center={coordinates?.coordinate || [6.8261981, 3.9942143]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={coordinates?.coordinate || [6.8261981, 3.9942143]}
              icon={L.icon({ iconUrl: customMarkerImage })}
            >
              <Popup>Joakins Farms Ng.</Popup>
            </Marker>
          </MapContainer>
        </div>
        <Form />
      </StyledMap>
      {/* <Test>
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </Test> */}
    </Container>
  );
};

export default Map;
