import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../colors";
import image1 from "../../assets/newsletter/image.png";
import useWindowDimensions from "../../hooks/useWindowSize";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import axios from "axios";

const { primary, gray, secondary } = Colors;

const Container = styled.div`
  background-color: #f0fedf;
  padding: 80px 10px;
`;

const StyledNewsletter = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;

const Section = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;

  .input_style {
    width: fit-content;
    margin-top: 30px;

    input {
      border: none;
      padding: 10px 50px 10px 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-family: "Nunito Sans", sans-serif;
      outline: none;
    }

    button {
      padding: 10px 20px;
      border: none;
      color: #fff;
      background-color: ${secondary};
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: pointer;
      font-family: "Nunito Sans", sans-serif;
    }
  }

  h1 {
    margin: 0;
    color: ${primary};
    font-size: 30px;
    font-weight: 800;
    font-family: "Nunito Sans", sans-serif;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    font-family: "Nunito Sans", sans-serif;
    color: ${gray};
  }

  span {
    font-size: 12px;
    margin-top: 15px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const ImgContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

const Newsletter = () => {
  const { width } = useWindowDimensions();

  const [email, setEmail] = useState("");

  const handleSubscribe = async () => {
    toast.loading("Processing...");
    try {
      const data = await axios.post(
        "https://34.228.80.5.nip.io/api/v1/createSubscriber",
        { email }
      );
      // console.log(data);
      toast.dismiss();
      toast.success("Subscribed successfully...");
    } catch ({ response }) {
      console.log(response);
      toast.dismiss();
      toast.error(response?.data?.message);
    }
  };

  return (
    <Container>
      <StyledNewsletter>
        <Section>
          <h1>Join our newsletter</h1>
          <p>Stay Informed, Stay Connected</p>
          <div className="input_style">
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter your email"
            />
            <button onClick={handleSubscribe}>Submit</button>
          </div>
          <span>
            By clicking Sign Up you're confirming that you agree with our{" "}
            <span className="underline">Terms and Conditions.</span>
          </span>
        </Section>
        <ImgContainer>
          <img
            src={image1}
            alt="About Us"
            width={width > 768 ? 460 : 250}
            height={width > 768 ? 290 : 180}
          />
        </ImgContainer>
      </StyledNewsletter>
    </Container>
  );
};

export default Newsletter;
