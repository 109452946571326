import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Colors } from "../../colors";
import { toast } from "react-toastify";
import axios from "axios";

const { secondary } = Colors;

interface ControlProps {
  margin_bottom?: string;
}

const StyledForm = styled.div`
  button {
    border: 1px solid ${secondary};
    background-color: ${secondary};
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 7px;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
  }

  .accept-terms {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 5px;

    input {
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
`;

const FormControl = styled.div<ControlProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: ${({ margin_bottom }) => margin_bottom || "10px"};

  label {
    font-size: 14px;
    color: #126a3a;
  }

  input {
    background-color: #fff5e2;
    padding: 10px 5px;
    outline: none;
    border: 1px solid ${secondary};
    font-family: "Lato", sans-serif;
    border-radius: 5px;
    transition-duration .3s;

    &:focus {
        background-color:#fff;
        padding : 10px 10px;
    }
  }

  textarea {
    background-color: #fff5e2;
    padding: 10px 5px;
    outline: none;
    border: 1px solid ${secondary};
    font-family: "Lato", sans-serif;
    border-radius: 5px;
    &:focus {
        background-color:#fff;
        padding : 10px 10px;
    }
  }
`;

const Form = () => {
  const { register, handleSubmit } = useForm();

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const onSubmit = async (values) => {
    if (!acceptedTerms)
      return toast.error("Kindly accept terms before submitting...");

    toast.loading("Processing...");
    try {
      const { data } = await axios.post(
        "https://34.228.80.5.nip.io/api/v1/contactUsForm",
        values
      );
    //   console.log(data);
      toast.dismiss();
      toast.success(data?.message);
    } catch ({ response }) {
      console.log(response);
      toast.dismiss();
      toast.error(response?.data?.message);
    }
    // console.log(values);
  };

  const handleTerms = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  //   console.log(acceptedTerms);

  return (
    <StyledForm>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <label>Name</label>
          <input
            type="text"
            required
            {...register("name", {
              required: true,
            })}
          />
        </FormControl>
        <FormControl>
          <label>Email</label>
          <input
            type="email"
            required
            {...register("email", {
              required: true,
            })}
          />
        </FormControl>
        <FormControl>
          <label>Message</label>
          <textarea
            cols={10}
            rows={5}
            {...register("message", {
              required: true,
            })}
          />
        </FormControl>
        <div className="accept-terms">
          <input
            onChange={handleTerms}
            checked={acceptedTerms ? true : false}
            type="checkbox"
          />
          <p>
            I accept the <span>Terms</span>
          </p>
        </div>
        <button type="submit">Submit</button>
      </form>
    </StyledForm>
  );
};

export default Form;
