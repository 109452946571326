import React from "react";
import Container from "../Container";
import { StyledValues, Grid, ValueContainer } from "../../styled/aboutus";
import { Colors } from "../../colors";
import box from "../../assets/vision/box.png";
import { Element } from "react-scroll";

const { paleGreen } = Colors;

const Values = ({ data }) => {
  // console.log(data);

  return (
    <Element name="ourvalues">
      <div style={{ backgroundColor: paleGreen }}>
        <Container color={paleGreen}>
          <ValueContainer>
            <span>Our Values</span>
            <h1 className="header">
              Rooted in Integrity, Cultivating Excellence
            </h1>
            <p className="sub-texts">
              At Joakins Farms, our values guide every decision, action, and
              interaction. Integrity, sustainability, and community are not just
              words; they are the pillars upon which we've built our legacy.
            </p>
            <Grid>
              {data?.map((value, index) => (
                <StyledValues key={index}>
                  <img src={box} alt="box" width={20} height={20} />
                  <h1>{value?.[0]}</h1>
                  <p>{value?.[1]}</p>
                </StyledValues>
              ))}
            </Grid>
          </ValueContainer>
        </Container>
      </div>
    </Element>
  );
};

export default Values;
