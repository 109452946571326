import React from "react";
import styled from "styled-components";
import Container from "../Container";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import useWindowDimensions from "../../hooks/useWindowSize";
import { baseUrl } from "../../url";
import { Colors } from "../../colors";

import image1 from "../../assets/products/image1.png";
import image2 from "../../assets/products/image2.png";
import image3 from "../../assets/products/image3.png";
import image4 from "../../assets/products/image4.png";
import image5 from "../../assets/products/image5.png";

import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/default";

// or only core styles
import "@splidejs/react-splide/css/core";

const Images = [
  { id: 1, image: image1 },
  { id: 2, image: image2 },
  { id: 2, image: image3 },
  { id: 2, image: image4 },
  { id: 2, image: image5 },
];

const { primary } = Colors;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to top, ${primary}, rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 1;
  }

  h1 {
    position: absolute;
    color: white;
    font-size: 30px;
    bottom: 5px;
    margin: 0;
    left: 14px;
    z-index: 2;
    font-family: "Nunito Sans", sans-serif;
  }

  @media (max-width: 1000px) {
    width: 200px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const StyledImage = styled.img`
  width: 320px;
  height: 350px;

  @media (max-width: 1000px) {
    width: 100%;
    height: 230px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 230px;
  }
`;

const ProductSlider = () => {
  const { width } = useWindowDimensions();

  const [data, setData] = React.useState([]);

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getProduct";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  React.useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const length = React.useMemo(() => {
    if (data.length < 3) return 1;
    return 3;
  }, [data]);

  // console.log(length);

  // console.log(data);

  return (
    <Splide
      aria-label="Testimonials"
      options={{
        perPage: width > 768 ? length : 1,
        arrows: false,
        direction: "ltr",
      }}
    >
      {data?.map((item, index) => (
        <SplideSlide key={index}>
          <ImageContainer>
            <StyledImage
              src={`${baseUrl}/${item?.url}/${item?.picture}`}
              alt="image"
            />
            <h1>{item?.productName}</h1>
          </ImageContainer>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default ProductSlider;
