import styled from "styled-components";
import { Colors } from "../../colors";

const { primary, gray, secondary, sub_secondary } = Colors;

export const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;

  h2 {
    color: ${primary};
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  h1 {
    margin: 0;
    font-size: 60px;
    color: ${primary};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 900;
    text-align: center;

    span {
      color: ${secondary};
    }
  }
  h3 {
    margin: 0;
    text-align: center;
    color: ${primary};
    font-family: "Nunito Sans", sans-serif;
    font-size: 25px;
    font-weight: 800;
  }
`;

export const SubText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  p {
    max-width: 550px;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    color: ${gray};
  }
`;

export const Partners = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;

  .splide {
    width: 100%;

    @media (max-width: 1000px) {
      width: 70%;
    }
  }

  li {
    .is-active {
      background-color: ${primary};
    }
  }

  h1 {
    color: ${primary};
    font-size: 40px;
    font-family: "Lato", sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
  }
`;

export const About = styled.div`
  background-color: ${secondary};
  padding: 30px 10px;
`;

export const StyledProducts = styled.div`
  margin-top: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    background-color: ${sub_secondary};
    color: ${primary};
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 14px;
  }

  p {
    color: ${primary};
    font-size: 30px;
    font-weight: 800;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 10px;
    width: 40%;
    text-align: center;

    @media (max-width: 1000px) {
      width: 100%;
      font-size: 30px;
      font-weight: 800;
    }
  }
`;

export const JoakinProducts = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 70%;
  }

  .splide {
    width: 100%;

    @media (max-width: 1000px) {
      width: 70%;
    }
  }

  li {
    .is-active {
      background-color: ${primary};
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
