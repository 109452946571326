import React from "react";
import Container from "../Container";
import { Colors } from "../../colors";
import { StyledHero } from "../../styled/aboutus";

const { paleGrey } = Colors;

const Hero = () => {
  return (
    <Container color={paleGrey}>
      <StyledHero>
        <h1>Cultivating Excellence, Nourishing Communities</h1>
        <p>
          At Joakins Farms Nigeria Limited, our mission is clear and unwavering
          - to cultivate excellence in integrated livestock farming and nourish
          communities with the highest standards of quality, sustainability and
          innovation.
        </p>
      </StyledHero>
    </Container>
  );
};

export default Hero;
