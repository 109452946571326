import React,{useState,useEffect} from "react";
import styled from "styled-components";
import { Colors } from "../../colors";
import useWindowDimensions from "../../hooks/useWindowSize";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Ratings from "../Ratings";

import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/default";

// or only core styles
import "@splidejs/react-splide/css/core";

const { secondary, primary, sub_secondary } = Colors;

const StyledTestimonials = styled.div`
  background-color: ${secondary};
  margin-top: 50px;
  padding: 20px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    background-color: ${sub_secondary};
    color: ${primary};
    padding: 3px 10px;
    border-radius: 8px;
    font-size: 14px;
  }

  .splide {
    width:70%;
    margin:0 auto;
  }

  .sub-heading {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 10px;
    text-align: center;
  }
`;

const StyledItem = styled.div`
  width: 450px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 50;

  .review {
    color: black;
    font-size: 14px;
    font-weight: 500;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 30px;

    @media (max-width: 768px) {
      font-size:12px;
    }
  }

  h1 {
    font-size: 20px;
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
  }

  h2 {
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0;
    font-family: "Nunito Sans", sans-serif;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  top: -60px;
  z-index: 100;
  display:block;
  border-radius:50%;
  overflow:hidden;
`;


const Slider = ({data}) => {

  // console.log(data);

  return (
    <Splide
      aria-label="Testimonials"
      options={{
        perPage: 1,
        arrows: false,
        direction: "ltr",
      }}
    >
      {data.map((item, index) => (
        <SplideSlide key={index}>
          <StyledItem>
            <ImageContainer>
              <img src={`https://34.228.80.5.nip.io/${item?.url}/${item?.picture}`} alt="Image" width={100} height={100} />
            </ImageContainer>
            <p className="review">{item?.review}</p>
            <h1>{item?.fName} {item?.lName}</h1>
            {/* <h2>{item.position}</h2> */}
            <Ratings rating={item.rating} />
          </StyledItem>
        </SplideSlide>
      ))}
    </Splide>
  );
};

const Testimonials = () => {

  const [data,setData] = useState([]);

  const getAboutUs = async () => {
    const url = "https://34.228.80.5.nip.io/api/v1/getReview?sort=-dateCreated";
    const response = await fetch(url);
    const data = await response.json();

    // console.log(data);
    return data;
  };

  useEffect(() => {
    getAboutUs()
      .then((data) => {
        // console.log(data?.message);
        setData(data?.message);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // console.log(data);

  // const { width } = useWindowDimensions();
  return (
    <StyledTestimonials>
      <span>Testimonials</span>
      <p className="sub-heading">What Our Customers Say</p>
      <Slider data={data} />
    </StyledTestimonials>
  );
};

export default Testimonials;
